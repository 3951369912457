.vjs-control-bar {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
    width: auto !important;
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
}
